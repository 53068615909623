import getEnv from './env.js';


const baseUrl = (getEnv("BASE_URI") != undefined ? getEnv("BASE_URI") : 'https://www.remindmevision.com') + '/api';

async function apiCall (method, route, body = null) {
    try {
        //var token = localStorage.getItem('JWTToken');
        var headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
        }
        /*if (token != null) {
            headers['Authorization'] = "Bearer " + token;
        }*/
        
        // if request method is GET, build query string from body object and append to route
        if (method == 'GET' && body != null) {
            var query = Object.keys(body).map(key => key + '=' + body[key]).join('&');
            route += '?' + query;
        }

        const response = await window.fetch(baseUrl + route, {
            headers,
            method,
            body: method != 'GET' && body != null ? JSON.stringify(body) : undefined,
            credentials: 'include'
        })
        var r = {status: response.status,}
        try {
            r.data = await response.json();
        } catch (err){
            console.log(err)
        }
        return r;
    } catch (error) {
        console.error('Error: ', error)
        throw error
    }
}

export { apiCall, baseUrl };